import React, { useState } from 'react';
import { Container, Grid, Typography, Card, CardHeader, AppBar, Toolbar, Avatar, Menu, MenuItem, IconButton, ButtonBase, CardContent, useMediaQuery, Button } from '@material-ui/core';
import TableCard from '../components/TableCard';
import { useParams, Route, useHistory, Link, useRouteMatch } from 'react-router-dom';
import { placesRef } from '../model';
import { useUpdFirestoreDoc, useLocalStorage, useUid } from '@clye-app/components';
import firebase from 'firebase/app';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import OpeningHours from '../components/OpeningHours';
import EditPlaceDescriptionDialog from '../components/EditPlaceDescriptionDialog';
import EditOpeningHoursDialog from '../components/EditOpeningHoursDialog';
import MapCard from '../components/MapCard';
import PhotoCard from '../components/PictureCard';

export default function PlacePage() {
    const { placeId } = useParams<{ placeId: string }>();
    const [place, setPlace] = useUpdFirestoreDoc(() => placeId ? placesRef.doc(placeId) : null, [placeId]);
    let photo = firebase.auth().currentUser?.photoURL;
    let [userMenu, setUserMenu] = useState<null | HTMLElement>(null);
    let history = useHistory();
    let match = useRouteMatch();
    let uid = useUid();

    let published = !!place?.publishedBy && !!place.publishedAt;

    const publish = () => {
        let uid = firebase.auth().currentUser?.uid;
        if (!uid) return;
        if (published) {
            setPlace({
                ...place,
                publishedBy: firebase.firestore.FieldValue.delete() as any,
                publishedAt: firebase.firestore.FieldValue.delete() as any,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
                updatedBy: uid ?? ""
            });
        } else {
            setPlace({
                ...place,
                publishedBy: uid,
                publishedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
                updatedBy: uid ?? ""
            });
        }
    };

    return <>
        <AppBar color="inherit">
            <Toolbar>
                <ButtonBase component={Link} to="/places" style={{ height: '100%', padding: 8, marginLeft: -8, display: 'flex' }}>
                    <Typography variant="h6" component="h1">{place?.name}</Typography>
                    <DownIcon />
                </ButtonBase>
                <div style={{ flex: 1 }} />
                <Button variant="contained" onClick={publish} color="primary" disabled disableElevation>
                    {published ? "Verstecken" : "Veröffentlichen"}
                </Button>
                <IconButton
                    onClick={e => setUserMenu(e.currentTarget)}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true">
                    <Avatar src={photo ?? undefined} />
                </IconButton>
                <Menu
                    anchorEl={userMenu}
                    open={!!userMenu}
                    onClose={() => setUserMenu(null)}
                    id="menu-appbar"
                    anchorReference="anchorEl"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}>
                    <MenuItem>Abmelden</MenuItem>
                    <ToggleDarkMode onClick={() => setUserMenu(null)} />
                </Menu>
            </Toolbar>
        </AppBar>
        <Toolbar />
        <Container style={{ paddingTop: 24 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <PhotoCard
                        placeId={placeId}
                        src={place?.photoUrl}
                        onChange={photoUrl => setPlace({
                            ...place,
                            photoUrl,
                            updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
                            updatedBy: uid ?? ""
                        })} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader
                            style={{ zIndex: 10 }}
                            title={place?.name}
                            action={<IconButton component={Link} to={match.url + "/edit/name"}>
                                <EditIcon />
                            </IconButton>}
                        />
                        <CardContent>
                            <Typography variant="body1">{place?.description}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MapCard place={place} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader
                            style={{ zIndex: 10 }}
                            title="Öffnungszeiten"
                            action={<IconButton component={Link} to={match.url + "/edit/opening-hours"}>
                                <EditIcon />
                            </IconButton>}
                        />
                        <CardContent><OpeningHours openingHours={place?.openingHours} /></CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <TableCard headCells={[
                        { id: "start", label: "Start", numeric: false },
                        { id: "end", label: "Ende", numeric: false },
                        { id: "name", label: "Name", numeric: false },
                        { id: "interested", label: "Interessenten", numeric: true },
                        { id: "visits", label: "Besuche", numeric: true }
                    ]} />
                </Grid>
            </Grid>
        </Container>
        <Route
            path={match.url + "/edit/name"}
            children={({ match }) => <EditPlaceDescriptionDialog
                open={!!match}
                value={place ?? {} as any}
                onClose={() => history.goBack()}
                onSave={nv => {
                    setPlace({
                        ...place,
                        ...nv,
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
                        updatedBy: uid ?? ""
                    }); history.goBack();
                }}
            />}
        />
        <Route
            path={match.url + "/edit/opening-hours"}
            children={({ match }) => <EditOpeningHoursDialog
                open={!!match}
                value={place ?? {} as any}
                onClose={() => history.goBack()}
                onSave={nv => { setPlace({ ...place, ...nv }); history.goBack(); }}
            />}
        />
    </>
}

function ToggleDarkMode({ onClick }: { onClick: () => void }) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkMode, setDarkMode] = useLocalStorage<boolean | null>("dark-mode", null);

    return <MenuItem onClick={() => {
        let dm = darkMode === null ? prefersDarkMode : darkMode;
        console.log(dm)
        setDarkMode(!dm);
        onClick();
    }}>Toggle Dark Mode</MenuItem>
}
