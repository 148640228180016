import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';

firebase.initializeApp(true ?
    {
        apiKey: "AIzaSyDrur4iZfSN5bY1bvlAp8OsMsUIV1Yygfs",
        authDomain: "clye-app.firebaseapp.com",
        databaseURL: "https://clye-app.firebaseio.com",
        projectId: "clye-app",
        storageBucket: "clye-app.appspot.com",
        messagingSenderId: "926378394821",
        appId: "1:926378394821:web:cfdd975f041d394555dcac",
        measurementId: "G-EEJ2MKJQKM"
    } : {
        apiKey: "AIzaSyAmLFTwPs5FBy0r375sy_mRZ06kigyIzVA",
        authDomain: "clye-dev.firebaseapp.com",
        databaseURL: "https://clye-dev.firebaseio.com",
        projectId: "clye-dev",
        storageBucket: "clye-dev.appspot.com",
        messagingSenderId: "627606081564",
        appId: "1:627606081564:web:55d3adbd9954ec92df8fd9",
        measurementId: "G-C6EJJEPHP7"
    });
if (navigator.userAgent !== 'ReactSnap') firebase.analytics();
