import React from "react";
import { Button, Toolbar, AppBar, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    page: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        "& > *": {
            margin: theme.spacing(4)
        }
    }
}))

export default function StartPage() {
    const classes = useStyles();
    return <>
        <AppBar color="transparent">
            <Toolbar>
                <Typography variant="h6">Places für Clye</Typography>
                <div style={{ flex: 1 }} />
                <Button component={Link} to="/login" variant="outlined" color="default">Anmelden</Button>
                <div style={{ width: 16 }} />
                <Button component={Link} to="/register" variant="contained" color="primary">Registrieren</Button>
            </Toolbar>
        </AppBar>
        <div className={classes.page}>
            <Typography variant="h1" align="center">Mehr und glücklichere Besucher</Typography>
            <Button component={Link} to="/register" variant="contained" color="primary" size="large">Registrieren</Button>
        </div>
    </>
}