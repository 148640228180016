import React, { useState, useEffect } from 'react';
import { Dialog, DialogProps, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, FormControl, MenuItem, Select, InputLabel, IconButton } from '@material-ui/core';
import { Place, OpenHoursSpecification, DEFAULT_OPENING, days, daysEn } from '../model';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { DateInput } from '@clye-app/components';

interface Props extends DialogProps {
    value: Place,
    onSave: (nv: Place) => void,
    onClose: () => void
}

export default function EditOpeningHoursDialog({ value, onSave, ...props }: Props) {
    let [v, setV] = useState(value);
    let [nv, setNv] = useState(DEFAULT_OPENING);
    useEffect(() => {
        if (props.open) { setV(value); setNv(DEFAULT_OPENING); }
    }, [value, props.open]);

    return <Dialog {...props}>
        <form style={{ width: 600 }} onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            onSave(v);
        }}>
            <DialogTitle>Öffnungszeiten</DialogTitle>
            <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                {v.openingHours?.map((oh, i) => (<EditOpeningHours
                    key={i}
                    value={oh}
                    onChange={nv => {
                        setV(p => ({
                            ...p,
                            openingHours: (p.openingHours ?? [])
                                .map((ov, j) => i === j ? nv : ov)
                        }));
                    }}
                    action={<IconButton onClick={() => {
                        setV(p => ({
                            ...p,
                            openingHours: (p.openingHours ?? [])
                                .filter((ov, j) => i !== j)
                        }));
                    }}>
                        <DeleteIcon />
                    </IconButton>}
                />))}
                <EditOpeningHours
                    value={nv}
                    onChange={setNv}
                    action={<IconButton onClick={() => {
                        setNv(DEFAULT_OPENING);
                        setV(p => ({ ...p, openingHours: [...(p.openingHours ?? []), nv] }));
                    }}>
                        <AddIcon />
                    </IconButton>}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Abbrechen</Button>
                <Button type="submit" variant="outlined">Speichern</Button>
            </DialogActions>
        </form>
    </Dialog>
}

export function EditOpeningHours({ value, onChange, action }: { action?: React.ReactChild, value: OpenHoursSpecification, onChange: (nv: OpenHoursSpecification) => void }) {
    return <Grid container spacing={1} style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item xs={3}>
            <FormControl fullWidth>
                <InputLabel>Tag</InputLabel>
                <Select value={value.dayOfWeek} onChange={e => onChange({ ...value, dayOfWeek: e.target.value as any })}>
                    {days.map((name, i) => <MenuItem key={name} value={daysEn[i]}>{name}</MenuItem>)}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={2}>
            <TextField label="Von" fullWidth value={value.opens}
                onChange={e => onChange({ ...value, opens: e.target.value })} />
        </Grid>
        <Grid item xs={2}>
            <TextField label="Bis" fullWidth value={value.closes}
                onChange={e => onChange({ ...value, closes: e.target.value })} />
        </Grid>
        <Grid item xs={2}>
            <DateInput label="Gültig ab" value={value.validFrom ?? ""}
                onChange={nv => onChange(removeUndefined({ ...value, validFrom: nv }))} />
        </Grid>
        <Grid item xs={2}>
            <DateInput label="Gültig bis" value={value.validThrough ?? ""}
                onChange={nv => onChange(removeUndefined({ ...value, validThrough: nv }))} />
        </Grid>
        <Grid item xs={1}>
            {action}
        </Grid>
    </Grid>
}

function removeUndefined<V>(inp: V): V {
    for (let key in inp) if (inp[key] === undefined) delete inp[key];
    return inp;
}
