import 'firebase/storage';
import React, { useState } from 'react';
import { Card, IconButton, CircularProgress, makeStyles } from '@material-ui/core';
import firebase from 'firebase/app';
import PhotoIcon from '@material-ui/icons/CameraAlt';
import ErrorIcon from '@material-ui/icons/Error';
import { nanoid } from 'nanoid';

const useStyles = makeStyles(theme => ({
    root: { height: '100%', position: 'relative' },
    upload: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    media: {
        height: '100%',
        backgroundPosition: 'center'
    },
    imageInput: { display: 'none' },
}))

export default function PhotoCard({ placeId, src, onChange }: { placeId: string, src?: string, onChange?: (url: string) => void }) {
    const classes = useStyles();
    const [uploading, setUploading] = useState(1);

    const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>, upd: (progress: number) => void): Promise<string | null> => {
        if (!e.target.files) return Promise.resolve(null);
        const file = e.target.files.item(0);
        if (!file) return Promise.resolve(null);
        upd(0);
        const userId = firebase.auth().currentUser?.uid;
        const uploadTask = firebase.storage().ref(`users/${userId}/places/${placeId}/photos/${nanoid()}`)
            .put(file);
        return new Promise((resolve, reject) => {
            uploadTask.on("state_changed", (snapshot) => {
                upd(Math.max(0, snapshot.bytesTransferred / snapshot.totalBytes - 0.2));
            }, reject, () => {
                resolve(uploadTask.snapshot.ref.getDownloadURL());
            });
        })
    };

    return <Card className={classes.root}>
        <div className={classes.media} style={{ backgroundImage: `url(${src})` }} />
        <input
            accept="image/*"
            className={classes.imageInput}
            id="imageInput"
            type="file"
            onChange={e => uploadFile(e, setUploading)?.then(downloadURL => {
                if (onChange && downloadURL) onChange(downloadURL);
            }).then(() => setUploading(1), err => {
                console.log(err);
                setUploading(-1);
            })}
        />
        <label htmlFor="imageInput" className={classes.upload}>
            <IconButton color="primary" aria-label="upload picture" component="span">
                {uploading >= 1 ? <PhotoIcon /> : (uploading < 0 ? <ErrorIcon
                    style={{ color: "#ff3333" }}
                /> : <CircularProgress
                        variant="determinate"
                        value={uploading * 100}
                    />)}
            </IconButton>
        </label>
    </Card>
}