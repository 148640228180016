import { Card, CardActionArea, CardHeader } from "@material-ui/core";
import React from "react";
import GoogleMapReact from 'google-map-react';
import LocationPin from "./LocationPin";
import { Place } from "../model";

export default function MapCard({ place }: { place?: Place }) {
    let pos = place?.position?.geopoint;
    return <Card style={{ height: '100%' }}>
        <CardActionArea
            component="a"
            target="blank"
            href={`https://www.google.com/maps/search/?api=1&query=${pos?.latitude},${pos?.longitude}&query_place_id=${place?.placeId}`}
            style={{ minHeight: 300, height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', }}>
            <GoogleMapReact
                options={{ draggable: false, fullscreenControl: false, zoomControl: false, disableDefaultUI: true }}
                bootstrapURLKeys={{ key: 'AIzaSyDrur4iZfSN5bY1bvlAp8OsMsUIV1Yygfs' }}
                defaultZoom={14}
                center={{
                    lat: pos?.latitude ?? 50,
                    lng: pos?.longitude ?? 8.5
                }}
                style={{ style: { position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 } }}
            >
                <LocationPin
                    lat={pos?.latitude ?? 50}
                    lng={pos?.longitude ?? 8.5}
                    text={""}
                />
            </GoogleMapReact>
            <div style={{ flex: 1 }} />
            <CardHeader style={{ zIndex: 10 }} title={place?.address} />
        </CardActionArea>
    </Card>
}