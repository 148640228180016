import React, { useState, useEffect } from 'react';
import { Dialog, DialogProps, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';
import { Place } from '../model';

interface Props extends DialogProps {
    value: Place,
    onSave: (nv: Place) => void,
    onClose: () => void
}

export default function EditPlaceDescriptionDialog({ value, onSave, ...props }: Props) {
    let [v, setV] = useState(value);
    useEffect(() => { if (props.open) setV(value) }, [value, props.open]);

    return <Dialog {...props}>
        <form onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            onSave(v);
        }}>
            <DialogTitle>Name und Beschreibung</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    autoFocus
                    fullWidth
                    value={v.name ?? ""}
                    onChange={({ target: { value } }) => setV(pv => ({ ...pv, name: value }))}
                />
                <TextField
                    label="Beschreibung"
                    multiline
                    rowsMax={20}
                    fullWidth
                    value={v.description ?? ""}
                    onChange={({ target: { value } }) => setV(pv => ({ ...pv, description: value }))}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Abbrechen</Button>
                <Button type="submit" variant="outlined">Speichern</Button>
            </DialogActions>
        </form>
    </Dialog>
}