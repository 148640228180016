import React, { useMemo } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import StartPage from './pages/Start';
import PlacesPage from './pages/Places';
import EditEventPage from './pages/EditEvent';
import { CssBaseline, ThemeProvider, createMuiTheme, useMediaQuery } from '@material-ui/core';
import PlacePage from './pages/Place';
import { useLocalStorage, LoginPage, RegisterPage } from '@clye-app/components';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode] = useLocalStorage<boolean | null>("dark-mode", null);

  const theme = useMemo(() => {
    return createMuiTheme({
      palette: {
        type: (darkMode == null ? prefersDarkMode : darkMode) ? 'dark' : 'light',
      },
    });
  }, [prefersDarkMode, darkMode]);

  return (<ThemeProvider theme={theme}>
    <>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route path="/login"><LoginPage /></Route>
          <Route path="/register"><RegisterPage /></Route>
          <Route path="/places/:placeId/events/:eventId/edit"><EditEventPage /></Route>
          <Route path="/places/:placeId"><PlacePage /></Route>
          <Route path="/places"><PlacesPage /></Route>
          <Route path="/"><StartPage /></Route>
        </Switch>
      </BrowserRouter>
    </>
  </ThemeProvider>);
}

export default App;
