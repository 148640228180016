import React from 'react';
import PlaceIcon from '@material-ui/icons/Place';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: { transform: 'translate(-50%, -100%)' }
}));

export default function LocationPin({ text }: { lat: number, lng: number, text: string }) {
    const classes = useStyles();
    return <PlaceIcon className={classes.root} color="secondary" />
}