import React, { useState } from "react";
import { makeStyles, List, LinearProgress, ListItem, ListItemText, AppBar, Toolbar, IconButton } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { useFirestore, useUid, GeoFirePoint } from "@clye-app/components";
import firebase from "firebase/app";
import { useHistory, Link } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import LocationPin from "../components/LocationPin";
import GoogleMapsPlaceInput, { GPlace } from "../components/GoogleMapsPlaceInput";
import { placesRef } from "../model";

const useStyles = makeStyles(theme => ({
    root: { position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 },
    content: { display: 'flex', height: '100vh' },
    sidebar: { width: 320, position: 'relative' },
    map: { flex: '1' }
}));

export default function PlacesPage() {
    const classes = useStyles();
    const uid = useUid();
    const history = useHistory();
    const places = useFirestore(fs => placesRef, [uid]);

    const [showPlace, setShowPlace] = useState<GPlace | null>(null);

    //TODO: find all places that have this user as admin
    //TODO: show the name of the location to click on
    //TODO: allow creating new locations

    const createPlace = async () => {
        if (!showPlace || !uid) return;
        let { placeId, address, name } = showPlace;
        let value = {
            name,
            address,
            createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
            createdBy: uid,
            placeId,
            owner: uid,
            position: new GeoFirePoint(showPlace.location.lat, showPlace.location.lng).data() as any,
        };
        console.log(value);
        await placesRef.doc(placeId).set(value, { merge: true });
        history.push(`/places/${placeId}/edit`);
    }

    return <div className={classes.root}>
        <LinearProgress hidden={!!places} />
        <div className={classes.content}>
            <div className={classes.sidebar}>
                <AppBar variant="elevation" color="transparent" position="absolute">
                    <Toolbar>
                        <div style={{ flex: 1 }}>
                            <GoogleMapsPlaceInput onChange={setShowPlace} />
                        </div>
                        <IconButton onClick={createPlace}>
                            <AddIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <List>
                    {places?.docs?.map(doc => {
                        let data = doc.data();
                        return <ListItem key={doc.id} button component={Link} to={`/places/${doc.id}`}>
                            <ListItemText primary={data.name} />
                        </ListItem>
                    })}
                </List>
            </div>
            <div className={classes.map}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDrur4iZfSN5bY1bvlAp8OsMsUIV1Yygfs' }}
                    defaultCenter={{ lat: 49.93, lng: 8.78 }}
                    defaultZoom={9}
                >
                    {showPlace && <LocationPin
                        lat={showPlace.location.lat}
                        lng={showPlace.location.lng}
                        text={""}
                    />}
                    {places?.docs?.map(place => {
                        let data = place.data();
                        return (<LocationPin
                            key={place.id}
                            lat={data.position?.geopoint.latitude ?? 0}
                            lng={data.position?.geopoint.longitude ?? 0}
                            text={data.name}
                        />);
                    })}
                </GoogleMapReact>
            </div>
        </div>
    </div>
}
