import React from 'react';
import { makeStyles } from '@material-ui/core';
import { OpenHoursSpecification, days, daysEn } from '../model';

const useStyles = makeStyles(theme => ({
    root: {},
    times: { margin: 0, padding: 0, '& > li': { listStyle: 'none', ...theme.typography.body2 } },
    day: { verticalAlign: 'baseline', ...theme.typography.body1, paddingRight: theme.spacing(2) }
}));

export default function OpeningHours({ openingHours = [] }: { openingHours?: OpenHoursSpecification[] }) {
    const classes = useStyles();
    let today = new Date().toISOString().slice(0, 10);

    return <table className={classes.root}>
        <tbody>
            {days.map((day, i) => {
                let relevant = openingHours.filter(oh => {
                    if (oh.dayOfWeek !== daysEn[i]) return false;
                    // make sure it is valid
                    if (oh.validFrom && oh.validFrom > today) return false;
                    if (oh.validThrough && oh.validThrough < today) return false;
                    return true;
                })
                return <tr key={day}>
                    <td className={classes.day}>{day}</td>
                    <td>
                        <ul className={classes.times}>
                            {relevant.map((oh, j) => (<li key={j}>{oh.opens} - {oh.closes}</li>))}
                        </ul>
                    </td>
                </tr>
            })}
        </tbody>
    </table>;
}