import * as firebase from 'firebase/app';
import 'firebase/firestore';

export interface User {
    chargebeeId?: string,
    /**
     * List of the cards to store the order in wich they are presented
     */
    cards?: string[]
    chargebee?: {
        subscription?: {
            plan_id?: string
        }
    }
}

export const User = {
    toFirestore(modelObject: User): firebase.firestore.DocumentData {
        return modelObject;
    },
    fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): User {
        return snapshot.data() as any;
    }
};

export interface Position {
    geohash: string,
    geopoint: firebase.firestore.GeoPoint
}

export interface Place {
    name: string,
    address: string,
    photoUrl?: string,
    createdAt: firebase.firestore.Timestamp,
    createdBy?: string,
    updatedAt?: firebase.firestore.Timestamp,
    updatedBy?: string,
    placeId: string,
    owner: string,
    description?: string,
    openingHours?: OpenHoursSpecification[],
    position?: Position,
    publishedAt?: firebase.firestore.Timestamp,
    publishedBy?: string,
}
export const Place = {
    toFirestore(modelObject: Place): firebase.firestore.DocumentData {
        return modelObject;
    },
    fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): Place {
        return snapshot.data() as any;
    }
};

type DayOfWeek =
    | "Friday"
    | "Monday"
    | "PublicHolidays"
    | "Saturday"
    | "Sunday"
    | "Thursday"
    | "Tuesday"
    | "Wednesday"

export let days = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
export let daysEn: DayOfWeek[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export interface OpenHoursSpecification {
    closes: string,
    dayOfWeek: DayOfWeek,
    opens: string,
    validFrom?: string,
    validThrough?: string,
}

export const DEFAULT_OPENING: OpenHoursSpecification = {
    dayOfWeek: "" as any,
    opens: "",
    closes: ""
}

export const placesRef = firebase.firestore().collection("places").withConverter(Place);
export const usersRef = firebase.firestore().collection("users").withConverter(User);
